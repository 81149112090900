export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/dashboard/profile',
    name: 'dashboard-profile',
    component: () => import('@/views/dashboard/profile/Profile.vue'),
  },
  {
    path: '/dashboard/lessons',
    name: 'dashboard-lessons',
    component: () => import('@/views/dashboard/lessons/Lessons.vue'),
  },
  {
    path: '/dashboard/reviews',
    name: 'dashboard-reviews',
    component: () => import('@/views/dashboard/reviews/Reviews.vue'),
  },
  {
    path: '/dashboard/more-lessons',
    name: 'dashboard-morelessons',
    component: () => import('@/views/dashboard/morelessons/More.vue'),
  },
  {
    path: '/dashboard/payments',
    name: 'dashboard-payment',
    component: () => import('@/views/dashboard/payments/payments.vue'),
  },
  {
    path: '/dashboard/invitations',
    name: 'dashboard-invitations',
    component: () => import('@/views/dashboard/invitations/invitations.vue'),
  },
]
