<template>
  <b-card>
    <h1>Reviews</h1>

    <div class="review-summary">
      <div class="summary-block">
        <h3>Total Reviews</h3>
        <h1 class="total-title">{{ reviews.length }}</h1>
      </div>

      <div class="summary-block">
        <h3>Average Rating</h3>
        <h1 class="average-title">{{ averageRating.toFixed(1) }}</h1>
        <b-form-rating
          class="average-rating"
          variant="warning"
          v-model="averageRating"
          readonly
          no-border
          size="lg"
        ></b-form-rating>
      </div>

      <div class="summary-block">
        <h3>Rating Distribution</h3>
        <ul>
          <li v-for="star in [5, 4, 3, 2, 1]" :key="star">
            <b-icon icon="star-fill" variant="warning" class="star-icon"></b-icon>
            {{ star }} Star{{ star > 1 ? 's' : '' }}: {{ ratingCount[star] || 0 }}
          </li>
        </ul>
      </div>
    </div>

    <div v-if="paginatedReviews.length > 0" class="reviews-list">
      <div v-for="(review, index) in paginatedReviews" :key="index" class="review-item">
        <div class="review-content">
          <b-form-rating
            class="average-rating"
            variant="warning"
            v-model="review.rating"
            readonly
            no-border
            size="lg"
            show-value
          ></b-form-rating>
          <h4>{{ review.client_name }}</h4>
          <p>{{ review.feedback || review.customerFeedback }}</p>
        </div>
      </div>

      <!-- Пагинация -->
      <b-pagination
        v-model="currentPage"
        :total-rows="reviews.length"
        :per-page="perPage"
        align="center"
        class="my-3"
      ></b-pagination>
    </div>

    <div v-else-if="!isLoading">Нет отзывов.</div>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      userInfo: null,
      isLoading: false,
      reviews: [],
      ratingCount: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
      currentPage: 1, // Текущая страница
      perPage: 10, // Количество отзывов на странице
    };
  },

  computed: {
    averageRating() {
      if (this.reviews.length === 0) return 0;
      const totalRating = this.reviews.reduce((sum, review) => sum + Math.round(parseFloat(review.rating)), 0);
      return totalRating / this.reviews.length;
    },

    paginatedReviews() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.reviews.slice(start, end);
    },
  },

  created() {
    this.isLoading = true;
    this.$http.get('/api/profile/get_instructor_info')
      .then(res => {
        this.userInfo = res.data;
        if (this.userInfo && this.userInfo.code) {
          this.getReviewsByCode(this.userInfo.code);
        } else {
          this.isLoading = false;
        }
      })
      .catch(err => {
        console.error(err);
        this.isLoading = false;
      });
  },

  methods: {
    getReviewsByCode(code) {
      this.$http.get(`/api/lessons/reviews_by_code?code=${code}`)
        .then(res => {
          this.reviews = res.data;
          this.calculateRatingCount();
          this.isLoading = false;
        })
        .catch(err => {
          console.error(err);
          this.isLoading = false;
        });
    },

    calculateRatingCount() {
      this.ratingCount = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
      this.reviews.forEach(review => {
        const roundedRating = Math.round(parseFloat(review.rating));
        if (this.ratingCount[roundedRating] !== undefined) {
          this.ratingCount[roundedRating]++;
        }
      });
    },
  }
};
</script>

<style scoped>
h1 {
  text-align: left;
  margin-bottom: 20px;
}

.review-summary {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 0px 30px;
  flex-wrap: wrap;
}

.summary-block {
  margin-right: 20px;
  padding: 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 30%;
  min-width: 250px;
}

.summary-block:not(:last-child) {
  border-right: 2px solid #ddd;
}

.summary-block ul {
  list-style-type: none;
  padding-left: 0;
}

.reviews-list {
  padding: 0px 30px 30px 30px;
  margin-top: 20px;
}

.review-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.total-title, .average-title {
  font-size: 70px;
  margin-bottom: 0;
}

.average-rating {
  padding: 0;
}

.star-icon {
  margin-right: 5px;
  color: #ffc107;
}

@media (max-width: 768px) {
  .review-summary {
    padding: 15px;
    flex-direction: column;
    align-items: center;
  }

  .summary-block {
    margin-right: 0;
    margin-bottom: 20px;
    flex: 1 1 100%;
    padding: 0 10px;
  }

  .summary-block:not(:last-child) {
    border-right: none;
  }

  .review-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .review-item .average-rating {
    max-width: 150px;
    width: 100%;
  }

  .total-title, .average-title {
    font-size: 50px;
  }

  .star-icon {
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 24px;
  }

  .total-title, .average-title {
    font-size: 40px;
  }

  .review-summary {
    padding: 10px;
  }

  .summary-block {
    flex: 1 1 100%;
    padding: 10px;
  }

  .review-item {
    padding: 5px;
  }

  .summary-block:not(:last-child) {
    border-right: none;
  }
}
</style>
