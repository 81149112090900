<template>
  <b-card class="p-4">
    <!-- Заголовок -->
    <div class="join-block text-center">
      <h1>Join our Instructor Support Group on Facebook</h1>
      <div class="d-flex justify-content-center gap-3">
        <b-button class="social-btn facebook-btn mr-1 mb-1" href="https://www.facebook.com/share/g/1YNB5ESmS1/?mibextid=wwXIfr">
          <b-icon icon="facebook" class="mr-1" /> Join
        </b-button>
      </div>
    </div>

    <!-- Заголовок -->
    <div class="text-center">
      <h1>Get Your FREE GOSKATE T-Shirt</h1>
      <b-button variant="primary" href="https://info73e039.clickfunnels.com/free-t-shirt-thank-you-page1627655061993" target="_blank">Get Started</b-button>
    </div>

    <!-- Шаг 1: Поделиться в соцсетях -->
    <div class="share-block pt-3 pb-3 mt-3 mb-3">
      <h3 class="text-center mb-1">Share your pro.goskate account on your personal social media and tag GOSKATE FB/IG:</h3>
      <div class="d-flex justify-content-center gap-3">
        <b-button class="social-btn facebook-btn mr-1" @click="shareOnFacebook(userInfo)">
          <b-icon icon="facebook" class="mr-1" /> Facebook
        </b-button>
        <b-button class="social-btn instagram-btn" @click="shareOnInstagram(userInfo)">
          <b-icon icon="instagram" /> Instagram
        </b-button>
      </div>
    </div>

    <!-- Шаг 2: Загрузка фото -->
    <div class="">
      <h3 class="text-center mb-1">Upload photos with GOSKATE branded Tee:</h3>
      <div class="d-flex justify-content-center">
        <b-button variant="primary" :to="{ name: 'dashboard-profile' }">
          Upload Photo
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      userInfo: null,
    };
  },

  created() {
    this.isLoading = true;
    this.$http.get('/api/profile/get_instructor_info')
      .then(res => {
        this.userInfo = res.data;
      })
      .catch(err => {
        console.error(err);
        this.isLoading = false;
      });
  },

  methods: {
    shareOnFacebook(userInfo) {
      const url = `https://pro.goskate.com/instructor?code=${userInfo.code}`;
      const text = "Check out GOSKATE!";
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}`;
      window.open(facebookUrl, '_blank');
    },

    shareOnInstagram(userInfo) {
      const url = `https://pro.goskate.com/instructor?code=${userInfo.code}`;
      const instagramUrl = `https://www.instagram.com/?url=${encodeURIComponent(url)}`;
      window.open(instagramUrl, '_blank');
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        alert(`You selected: ${file.name}`);
        // Logic for file upload to the server (e.g., via an API)
      }
    },
  },
};
</script>

<style scoped>
.social-btn {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}

.facebook-btn {
  background-color: #3b5998 !important;
  color: white;
  border: none;
}

.facebook-btn:hover {
  background-color: #2d4373 !important;
}

.instagram-btn {
  background: linear-gradient(45deg, #f56040, #fd1d1d) !important;
  color: white !important;
  border: none !important;
}

.instagram-btn:hover {
  background: linear-gradient(45deg, #d84e38, #c91616) !important;
}

button:hover {
  cursor: pointer;
}

.b-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .social-btn {
    font-size: 14px;
    padding: 8px 16px;
  }

  .b-card {
    padding: 15px;
  }
}

.share-block{
  border-bottom: 1px solid;
  border-top: 1px solid;
}

.join-block{
  border-bottom: 1px solid;
  padding-bottom: 25px;
  margin-bottom: 35px;
}
</style>
