export default [
  {
    header: 'Main',
  },
  {
    title: 'Dashboard',
    route: 'dashboard-ecommerce',
    icon: 'HomeIcon',
  },
  {
    title: 'Profile',
    route: 'dashboard-profile',
    icon: 'UserIcon',
  },
  {
    title: 'Pay and lessons',
    route: 'dashboard-lessons',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Reviews',
    route: 'dashboard-reviews',
    icon: 'StarIcon',
  },
  {
    title: 'Get more students',
    route: 'dashboard-morelessons',
    icon: 'BarChartFill',
  },
  {
    title: 'Invitations',
    route: 'dashboard-invitations',
    icon: 'InboxIcon',
  },
  /* {
    title: 'Calendar',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
  }, */
  /* {
    title: 'Payments',
    route: 'dashboard-payment',
    icon: 'CreditCardIcon',
  }, */
]
